.example-enter {
  opacity: 0;
}

.example-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.example-leave {
  opacity: 1;
}

.example-leave-active {
  opacity: 0;
  transition: opacity 300ms ease-in;
}

.alert-enter {
  opacity: 0;
  transform: scale(1);
}
.alert-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 1000ms, transform 1000ms;
}
.alert-exit {
  opacity: 1;
}
.alert-exit-active {
  opacity: 0;
  transform: scale(1);
  transition: opacity 1000ms, transform 1000ms;
}