.image-container {
    position: absolute;
    display: inline-block;
    height: 50vh;
    top: 0;
    right: 0;
}

.image {
    height: 100%;
    width: auto;
    object-fit: cover;
}

.text-overlay {
    position: absolute;
    top: 10px;
    right: 10px;
    color: white;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 15px;
    padding-left: 40px;
    border-radius: 20px;
    font-size: 40px;
    text-align: right;
}

.large-text {
    font-size: 70px;
}

.small-text {
    font-size: 40px;
}

.bold-text {
    font-weight: bold;
}